import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 40px;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  .status {
    &.accepted {
      color: #15c39a;
    }
    &.rejected {
      color: red;
    }
  }
`;

export const Section = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #0747a6;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 25px;
`;

export const LabelValueWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Label = styled.span`
  font-weight: bold;
  color: #1a21ba;
  font-size: 16px;
  margin-right: 8px;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

export const Value = styled.span`
  color: #333;
  font-size: 16px;
  line-height: 1.5;
`;

export const ModalTextarea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: 1px solid #c1c7d0;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #5e6c84;
  background-color: #f4f5f7;
  margin-top: 10px;
`;

export const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const ActionButton = styled.button`
  background-color: #0747a6;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:disabled {
    background-color: #79bbe6;
    cursor: not-allowed;
  }
`;

export const NotesSection = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e9f5fc;
  border-radius: 8px;
  h4 {
    color: #0747a6;
    margin-bottom: 10px;
  }
  p {
    color: #333;
    font-size: 16px;
  }
`;

export const Text = styled.p`
  margin-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #091e42;
  transition: 1.5s;
`;

export const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #d1d5db;
  border-radius: 8px 8px 0 0;

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }

  button {
    background: none;
    border: none;
    font-size: 18px;
    color: #ff4c4c;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f8d7da;
    }
  }
`;
