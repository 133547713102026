import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { TableContainer } from 'pages/Admin/Complaints/components/style';
import { ModalFooter } from 'components/AppModal/styles';
import { ModalButton, WrapperAddEmployeeModal } from 'pages/Employee/components/style';

import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

// import MenuBar from 'components/common/MenuBar/CandidateMenuBar';
import {
  getSupervisorEmployeeKpi,
  updateEmployeeAppraisalForSupervisor,
  editObjectives,
  // getEmployeeKpi,
} from 'redux/actions/adminAppraisal';
import { useQuery } from 'utils/useQuery';
import PageContainer from '../../components/common/PageContainer';
import { Wrapper, ModalTextarea, Text, Error } from './styles';

const Supervisor = () => {
  // const token = getToken();
  // const user = decodeToken(token);
  const query = useQuery('pathto');
  const employeeID = query.get('employee');
  const appraisalID = query.get('appraisal');
  const kpiId = query.get('kpi');

  const appraisals = useSelector((state) => state.appraisal.appraisals);
  const dispatch = useDispatch();
  // const token = new URLSearchParams(history.location.search);
  const [addResponse, setAddResponse] = useState(false);
  const [weightError, setWeightError] = useState(false);
  // const [submitting, setSubmitting] = useState(false);
  // const [editable, setEditable] = useState(false);
  // const [editInProgress, setEditInProgress] = useState(false);
  const [kpi, setKpi] = useState({});
  const [detail, setDetail] = useState({});
  const startDate = moment(appraisals?.kpi?.appraisal.start_date).format('MM-D-YYYY');
  const endDate = moment(appraisals?.kpi?.appraisal.end_date).format('MM-D-YYYY');

  useEffect(() => {
    const fetchEmployeeKpi = async () => {
      const employee_response = await dispatch(
        getSupervisorEmployeeKpi(employeeID, appraisalID, kpiId)
      );
      setDetail(employee_response);
    };
    fetchEmployeeKpi();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const info = appraisals?.results?.find(
      (result) => result.kpi.category === 'EMPLOYEE'
    );
    setKpi(info?.kpi);
    // setDetail(info);
  }, [appraisals]);

  const onSubmit = async (values, { resetForm }) => {
    const { objectives, ...rest } = values;
    // setSubmitting(true);
    dispatch(updateEmployeeAppraisalForSupervisor(detail.id, rest));
    // setSubmitting(false);
    setAddResponse(false);
    resetForm();
  };

  const onBlur = (event, objectiveId) => {
    const weightCheck = detail.objectives.reduce(
      (previousValue, currentValue) => ({
        weight: previousValue?.weight + currentValue?.weight,
      }),
      { weight: 0 }
    );

    if (parseInt(weightCheck.weight, 10) !== 100) {
      setWeightError('Aggregate weight must be 100');
      return;
    }
    dispatch(editObjectives(objectiveId, { weight: event.target.value }, appraisalID));
  };

  const handleChange = (evt, position) => {
    setWeightError('');
    const newObjectives = detail.objectives.map((obj, index) => {
      const newWeight = index === position ? evt.target.value : obj.weight;
      return {
        ...obj,
        weight: newWeight,
      };
    });
    setDetail({
      ...detail,
      objectives: [...newObjectives],
    });
  };

  return (
    <>
      <PageContainer>
        <>
          <Wrapper status={detail?.status}>
            <div className="app">
              <div className="text_centering">
                <h5 className="text_heading">Supervisor</h5>
              </div>

              <Text className="text_heading">{`Name: ${
                detail?.employee?.user?.firstname || ''
              } ${detail?.employee?.user?.lastname || ''}`}</Text>
              <Text className="text_heading">{`Function: ${
                detail?.employee?.job_role?.department?.name || ''
              }`}</Text>
              <Text className="text_heading">
                {`Job Title: ${detail?.employee?.job_role?.name || ''}`}
              </Text>
              <Text className="text_heading">Region: Nigeria</Text>
              <Text className="mb-1 text_heading">
                Year Covered: {new Date(detail?.created_at).getFullYear() || ''}{' '}
              </Text>

              {(detail?.status === 'ACCEPTED' || detail?.status === 'REJECTED') && (
                <div className="status_container">
                  <span>Status: </span>
                  <span className="status_button">
                    {detail?.status === 'ACCEPTED' ? 'Accepted' : 'Rejected'}
                  </span>
                </div>
              )}

              <TableContainer className="category-table" min="display">
                <>
                  <table>
                    <thead>
                      <tr>
                        <th className="text_heading">S/N</th>
                        <th className="text_heading">Objective</th>
                        <th className="text_heading">Start</th>
                        <th className="text_heading">End</th>
                        <th className="text_heading">
                          <span>Weight (100%) </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {detail &&
                        detail?.objectives &&
                        detail?.objectives?.length > 0 &&
                        detail?.objectives?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.text}</td>
                            <td>{startDate}</td>
                            <td>{endDate}</td>
                            <td>
                              <input
                                value={detail?.objectives[index].weight}
                                onChange={(e) => handleChange(e, index)}
                                onBlur={(e) => onBlur(e, detail?.objectives[index].id)}
                              />
                              {weightError && <Error>{weightError}</Error>}
                            </td>
                          </tr>
                        ))}
                      {kpi?.objectives?.length === 0 && <div>No Objectives</div>}
                    </tbody>
                  </table>
                </>
              </TableContainer>
              <button
                type="button"
                className="label_name m-3 p-3 comment_button"
                disabled={detail?.status === 'ACCEPTED' || detail?.status === 'REJECTED'}
                onClick={() => setAddResponse(true)}
              >
                comment
              </button>
            </div>
          </Wrapper>

          <WrapperAddEmployeeModal
            show={addResponse}
            onHide={() => {
              setAddResponse(false);
            }}
            animation={false}
          >
            <Formik
              initialValues={{
                comment: '',
                status: '',
              }}
              validationSchema={Yup.object({
                comment: Yup.string().required('Please Add your Comment'),
              })}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                touched,
                handleBlur,
                errors,
                resetForm,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="detail_to_wrap">
                    <Modal.Header
                      closeButton
                      style={{
                        boxShadow: '0px 4px 20px rgba(199, 223, 241, 0.25',
                      }}
                    >
                      <b> Comment </b>
                    </Modal.Header>
                    <div>
                      <div className="form_grid form-21 m-3">
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="scorecard-modal-accept__text">
                            Are you sure you want to submit these comment?
                          </p>
                        </div>
                        <ModalTextarea
                          name="comment"
                          placeholder="Please provide the reason for declininging the brief."
                          type="textarea"
                          onBlur={handleBlur}
                          value={values.comment}
                          onChange={handleChange}
                        />
                        {touched.comment && errors.comment ? (
                          <Error>{errors.comment}</Error>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <ModalFooter>
                    <ModalButton
                      type="submit"
                      disabled={detail?.status === 'REJECTED'}
                      onClick={() => {
                        setFieldValue('status', 'REJECTED');
                        handleSubmit();
                      }}
                    >
                      Reject
                    </ModalButton>
                    <ModalButton
                      className="btn-2"
                      action="action"
                      type="submit"
                      onClick={() => {
                        setFieldValue('status', 'ACCEPTED');
                        handleSubmit();
                      }}
                    >
                      Accept
                    </ModalButton>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </WrapperAddEmployeeModal>
        </>
      </PageContainer>
    </>
  );
};

export default Supervisor;
