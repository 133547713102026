import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { ModalFooter } from 'components/AppModal/styles';
import { ModalButton, WrapperAddEmployeeModal } from 'pages/Employee/components/style';

import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import {
  getSupervisorEmployeeLeave,
  updateEmployeeLeaveForSupervisor,
} from 'redux/actions/adminAppraisal';

import PageContainer from '../../components/common/PageContainer';
// import { Wrapper, ModalTextarea, Text, Error } from './styles';
import {
  Wrapper,
  Section,
  DetailContainer,
  LabelValueWrapper,
  Label,
  Value,
  ModalTextarea,
  Error,
  NotesSection,
  ActionButton,
} from './styles';

const ScheduledLeave = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [addResponse, setAddResponse] = useState(false);
  const [detail, setDetail] = useState({});

  const fetchEmployeeLeave = async () => {
    const response = await dispatch(getSupervisorEmployeeLeave(employeeId));
    setDetail(response);
  };

  useEffect(() => {
    fetchEmployeeLeave();
    // eslint-disable-next-line
  }, [dispatch]);

  const isSupervisor1 = location.pathname.includes('/supervisor/leave/');

  const statusChecker = isSupervisor1
    ? detail?.line_manager1_action
    : detail?.line_manager2_action;

  const onSubmit = async (values, { resetForm }) => {
    const { comment, status } = values;
    const payload = isSupervisor1
      ? { line_manager1_comments: comment, line_manager1_action: status }
      : { line_manager2_comments: comment, line_manager2_action: status };
    dispatch(updateEmployeeLeaveForSupervisor(detail.id, payload));
    fetchEmployeeLeave();
    setAddResponse(false);
    resetForm();
  };

  const handleClick = () => {
    const url = detail?.handover_document;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Invalid URL');
    }
  };
  console.log('detail', detail);
  return (
    <PageContainer>
      <Wrapper status={detail?.status}>
        <h2>Leave Review</h2>
        {/* Personal Info Section */}
        <Section>
          <h3>Personal Information</h3>
          <DetailContainer>
            <LabelValueWrapper>
              <Label>First Name:</Label>
              <Value>{detail?.employee?.user_info?.firstname}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Last Name:</Label>
              <Value>{detail?.employee?.user_info?.lastname}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Email:</Label>
              <Value>{detail?.employee?.user_info?.email}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Gender:</Label>
              <Value>{detail?.employee?.user_info?.gender}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Phone Number:</Label>
              <Value>{detail?.employee?.user_info?.phone}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Address:</Label>
              <Value>{detail?.employee?.user_info?.address || 'N/A'}</Value>
            </LabelValueWrapper>
          </DetailContainer>
        </Section>

        {/* Job Info Section */}
        <Section>
          <h3>Job Information</h3>
          <DetailContainer>
            <LabelValueWrapper>
              <Label>Company:</Label>
              <Value>{detail?.employee?.company_info?.name || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Branch:</Label>
              <Value>{detail?.employee?.branch_info?.name || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Department:</Label>
              <Value>{detail?.employee?.department_info?.name || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Job Role:</Label>
              <Value>{detail?.employee?.jobrole_info?.name || 'N/A'}</Value>
            </LabelValueWrapper>
          </DetailContainer>
        </Section>

        {/* Leave Info Section */}
        <Section>
          <h3>Leave Details</h3>

          <DetailContainer>
            <LabelValueWrapper>
              <Label>Leave Address:</Label>
              <Value>{detail?.leave_address || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Leave Type:</Label>
              <Value>{detail?.leave_type?.name || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Leave Description:</Label>
              <Value>{detail?.leave_type?.description || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Leave Days:</Label>
              <Value>{detail?.num_of_days || 'N/A'}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Leave Starts:</Label>
              <Value>{`${detail?.start_date || 'N/A'} `}</Value>
            </LabelValueWrapper>
            <LabelValueWrapper>
              <Label>Leave Ends:</Label>
              <Value>{`${detail?.end_date || 'N/A'}`}</Value>
            </LabelValueWrapper>

            {detail?.handover_document && (
              <LabelValueWrapper>
                <Label>Handover Document:</Label>
                <button type="button" onClick={handleClick} className="link-button">
                  Open Document
                </button>
              </LabelValueWrapper>
            )}

            <LabelValueWrapper>
              <Label>Relief Officer Needed:</Label>
              <Value>{detail?.relief_officer_needed ? 'Yes' : 'No'}</Value>
            </LabelValueWrapper>

            {detail?.relief_officer_needed && (
              <>
                <LabelValueWrapper>
                  <Label>Relief Officer Name:</Label>
                  <Value>{detail?.relief_officer_name}</Value>
                </LabelValueWrapper>
                <LabelValueWrapper>
                  <Label>Relief Officer Email:</Label>
                  <Value>{detail?.relief_officer_email}</Value>
                </LabelValueWrapper>
              </>
            )}
            {(detail?.status === 'ACCEPTED' || detail?.status === 'REJECTED') && (
              <LabelValueWrapper>
                <Label>Status:</Label>
                <Value className={`status ${detail?.status?.toLowerCase()}`}>
                  {detail?.status === 'ACCEPTED' ? 'Accepted' : 'Rejected'}
                </Value>
              </LabelValueWrapper>
            )}
          </DetailContainer>

          {detail?.notes && (
            <NotesSection>
              <h4>Leave Note</h4>
              <p>{detail?.notes}</p>
            </NotesSection>
          )}
        </Section>

        <ActionButton
          disabled={statusChecker === 'APPROVED'}
          onClick={() => setAddResponse(true)}
        >
          Comment
        </ActionButton>

        <WrapperAddEmployeeModal
          show={addResponse}
          onHide={() => {
            setAddResponse(false);
          }}
          animation={false}
        >
          <Formik
            initialValues={{
              comment: '',
              status: '',
            }}
            validationSchema={Yup.object({
              comment: Yup.string().required('Please Add your Comment'),
            })}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              errors,
              resetForm,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="detail_to_wrap">
                  <Modal.Header
                    closeButton
                    style={{
                      boxShadow: '0px 4px 20px rgba(199, 223, 241, 0.25',
                    }}
                  >
                    <b> Comment </b>
                  </Modal.Header>
                  <div>
                    <div className="form_grid form-21 m-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="scorecard-modal-accept__text">
                          Are you sure you want to submit these comment?
                        </p>
                      </div>
                      <ModalTextarea
                        name="comment"
                        placeholder="Please provide the reason for declininging the brief."
                        type="textarea"
                        onBlur={handleBlur}
                        value={values.comment}
                        onChange={handleChange}
                      />
                      {touched.comment && errors.comment ? (
                        <Error>{errors.comment}</Error>
                      ) : null}
                    </div>
                  </div>
                </div>
                <ModalFooter>
                  <ModalButton
                    type="submit"
                    disabled={statusChecker === 'REJECTED'}
                    onClick={() => {
                      setFieldValue('status', 'DECLINED');
                      handleSubmit();
                    }}
                  >
                    Reject
                  </ModalButton>
                  <ModalButton
                    className="btn-2"
                    action="action"
                    type="submit"
                    onClick={() => {
                      setFieldValue('status', 'APPROVED');
                      handleSubmit();
                    }}
                  >
                    Accept
                  </ModalButton>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </WrapperAddEmployeeModal>
      </Wrapper>
    </PageContainer>
  );
};

export default ScheduledLeave;
